.Header .navbar {
    margin-bottom: 0px;
    padding: 0px;
}

.Header .navbar-brand {
    padding: 0 15px;
    min-height: 40px;
    max-height: 40px;
    line-height: 40px;
    font-size: 20px;
}

.Header .navbar-toggler {
    /* (40px - button height 30px) / 2 = 5px */
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right:5px;
    outline: none;
    line-height: 15px;
    padding: 2px 5px !important;
    font-size: 15px;
}

.Header .navbar-nav>li>a {
    /* (40px - line-height of 30px) / 2 = 5px */
    padding: 0px 5px !important;
    line-height: 30px;
    font-size: 15px;
}

.Header #favicon {
    min-height: 25px;
    max-height: 25px;
    display: inline;
}
