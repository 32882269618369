.scrollbar {
    overflow: auto;
}

.scrollbar-primary::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4285F4;
}

.scrollbar-danger::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ff3547;
}

.scrollbar-warning::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #FF8800;
}

.scrollbar-success::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00C851;
}

.scrollbar-info::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #33b5e5;
}

.scrollbar-default::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #888888;
}

.scrollbar-secondary::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aa66cc;
}