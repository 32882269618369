main {
    margin: 0px;
    padding: 0px;
    min-height: calc(100vh - 194px);
    width: 100%;
}

main>div {
    min-height: calc(100vh - 194px);
    padding: 15px 0px;
}

footer {
    background: var(--gray-dark);
    color: var(--light);
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    margin: 0px;
    padding: 15px;
    text-align: center;
}

body {
    background-color: #1c3c63;
    background-image: url("../images/bg-azul.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
    -webkit-background-size: 100% 100vh;
    -moz-background-size: 100% 100vh;
    -o-background-size: 100% 100vh;
    background-size: 100% 100vh;
}

.private {
    display: none;
}

br {
    line-height: 15px !important;
}

.carousel-inner>.item, .carousel-inner>.item>img {
    width: 100%;
}

th, td {
    text-align: center !important;
    vertical-align: middle !important;
}

.carousel-indicators {
    margin: 0px 25px 8px 25px;
    padding: 3px 0px;
    top: calc(60% + 25px);
    bottom: initial;
    width: 100px;
    left: initial;
    right: 25px;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px;
    text-align: left;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
}

.carousel-caption h3 {
    margin-right: 150px;
}

.carousel-control-prev, .carousel-control-next {
    top: calc(60% + 29px);
    bottom: initial;
    width: 25px;
    z-index: 12;
    right: 25px;
}

.carousel-control-prev {
    left: initial;
    right: 150px;
}

.carousel {
    margin-top: -15px;
}

.card-body>*:last-child {
    margin-bottom: 0px;
}